import axios from "axios";
import {checkJWT, getIdShop, getIdUser, logout} from "./Service";
import download from 'js-file-download';

export const API_URL = "http://45.9.40.232:8080/api/v1"
const API_URL_USER = "http://45.9.40.232:8080/api/v1/user"

export const config = {
    headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        "Accept" : "*/*",
        "Access-Control-Allow-Origin" : '*',
        "Content-Type": "application/json"
    },
};

export function authService(login, password) {
    return axios.post("http://45.9.40.232:8080/auth", {login, password})
        .then(response => {
            if (response.data !== "error" && response.status === 200) {
                return response.data;
            } else {
                return "error";
            }
        });
}

export function getStockList() {
    return axios.get("http://45.9.40.232:8080/api/v1/stock", config).then(response => {
        return response.data
    })
}

export function getSupply() {
    return axios.get(API_URL + "/follow/stat/" + getIdShop(), config).then(r => {
        return r.data;
    })
}

export function saveFollow(data) {
    return axios.post(API_URL + "/follow", data, config).then(r => {
        return r.data;
    })
}

export function getFollowList() {
    return axios.get(API_URL + "/follow/" + getIdShop(), config).then(r => {
        return r.data;
    })
}

export function updateSupply() {
    let data = [];
    return axios.put(API_URL + "/supply/plan",data, config).then(r => {
        return r.data;
    })
}

export async function getNameShop() {
    if (checkJWT()) {
        return axios.post(API_URL_USER + "/shopname", {"id" : getIdUser()}, config).then(response => {
            if (response.status === 200) {
                return response.data;
            }
            else logout();
        })
    } else {
        logout();
    }
}

export async function getSizesByBarcode(barcode, idShop) {
    return axios.get(API_URL_USER + "/shop/product/by/barcode/" + barcode + "/" + idShop, config).then(response => {
        return response.data;
    })
}

export async function getBarcodesList() {
    return axios.get(API_URL_USER + "/shop/product/barcode/list/" + getIdShop(), config).then(response => {
        return response.data;
    })
}

export async function getIdShopByUser() {
  if (checkJWT()) {
    return axios.get(API_URL_USER + "/shopname/id/" + getIdUser(), config).then(response => {
      if (response.status === 200) {
        return response.data;
      }
      else logout();
    })
  } else {
    logout();
  }
}

export async function getUserName() {
    if(checkJWT()) {
        return axios.post(API_URL_USER + "/profile", {"id" : getIdUser()}, config).then(
            response => {
                if(response.status === 200) {
                    return response.data;
                }
                logout();
            }
        )
    } else {
        logout();
    }
}

export async function getProductList(page) {
    if (checkJWT()) {
        return axios.post(API_URL + "/shop/products/" + page, {"id" : getIdUser()}, config)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
                logout();
            })
    }
}

export async function getProducts() {
    if (checkJWT()) {
        return axios.get(API_URL + "/shop/products/names/" + getIdUser(), config)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
                logout();
            })
    }
}

export async function getRequests() {
    if (checkJWT()) {
        return axios.get(API_URL + "/get/request/" + getIdUser(), config)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
                logout();
            })
    }
}

export async function getDataFromNameProduct(name,shopId) {
    if (checkJWT()) {
        return axios.get(API_URL + "/product/" + name + "/" + shopId , config)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
                logout();
            })
      }
}

export async function getSizesByName(name, idShop) {
    if (checkJWT()) {
        return axios.get(API_URL + "/product/get/size/" + name + "/" + idShop , config)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
                logout();
            })
    }
}


export async function getDataFromNameAndSizeProduct(name, size, idShop) {
    if (checkJWT()) {
        return axios.get(API_URL + "/product/get/" + name + "/" + size + "/" + idShop , config)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
                logout();
            })
    }
}

export async function saveRequest(date, stockId) {
    if (checkJWT()) {
        let data = {
            "date": date,
            "idUser": {
                "id": getIdUser()
            },
            "stock" : {
                "id" : stockId
            }

        }
        return axios.post(API_URL + "/add/request", data, config)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
                logout();
            })
    }
}

export async function saveRequestList(requestId, data) {
    if (checkJWT()) {
        let body =
            {
                "amount": data.amount,
                "idShopProduct": {
                    "idShop": {
                        "id": getIdShop()
                    },
                    "idProduct": {
                        "idArtCode": {
                            "artCode": data.artcode
                        },
                        "idBarcode": {
                            "barcode": data.barcode,
                            "idModel": {
                                "name": data.name,
                                "img": null,
                                "idSize": {
                                    "number": data.size
                                },
                                "idColor": {
                                    "colorName": data.color
                                }
                            }
                        }
                    }
                },
                "idRequest": {
                    "id": requestId,
                    "idUser": {
                        "id": getIdUser()
                    }
                }
            }
        return axios.post(API_URL + "/request/list/add", body, config)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
                logout();
            })
    }
}

export async function downloadFile(requestId) {
    let localConfig = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            "Accept" : "*/*",
            "Access-Control-Allow-Origin" : '*',
            'Content-Type': 'blob'
        },
    }
    if (checkJWT()) {
        axios.get(API_URL + "/file/" + requestId, localConfig).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'template.xlsx');
            document.body.appendChild(link);
            link.click();
        })
    }
}


export async function getRequestListByRequestId(requestId) {
    if (checkJWT()) {
       return  axios.get(API_URL + "/request/list/" + requestId, config).then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                logout();
            }
        })
    } else {
        logout();
    }
}

export async function removeRequestList(requestId, data) {
  if (checkJWT()) {
    let body =
      {
        "idShopProduct": {
          "idShop": {
            "id": 3
          },
          "idProduct": {
            "idArtCode": {
              "artCode": data.artcode
            },
            "idBarcode": {
              "barcode": data.barcode,
              "idModel": {
                "name": data.name,
                "img": null,
                "idSize": {
                  "number": data.size
                },
                "idColor": {
                  "colorName": data.color
                }
              }
            }
          }
        },
        "idRequest": {
          "id": requestId,
          "idUser": {
            "id": getIdUser()
          }
        }
      }
    return axios.post(API_URL + "/request/list/remove", body, config)
      .then(response => {
        if (response.status === 200) {
          return response.data;
        }
        logout();
      })
  }
}