import jwt_decode from "jwt-decode";

export function logout() {
    localStorage.removeItem("token");
    window.location.reload();
}

export function checkJWT() {
    if (localStorage.getItem("token")){
        try {
            let a = jwt_decode(localStorage.getItem("token"))['role'];
            return true;
        } catch (e) {
            logout();
            return false;
        }
    }
}

export function isAdmin () {
    if (checkJWT()) return jwt_decode(localStorage.getItem("token"))['role'] === "ROLE_ADMIN";
}

export function getIdUser () {
    if (checkJWT()) return jwt_decode(localStorage.getItem("token"))['id_user'];
}

export function getIdShop () {
    if (checkJWT()) return jwt_decode(localStorage.getItem("token"))['id_shop'];
}