import {useEffect, useState} from "react";
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import {Button, TabPanel, Typography} from "@mui/joy";
import {getStockList, updateSupply, getSupply} from "../../util/API";
import Table from '@mui/joy/Table';
import {Alert, Snackbar} from "@mui/material";

export const FollowList = () => {

    const [stock, setStock] = useState([]);
    const [supply, setSupply] = useState([]);

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
        updateSupply()
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {

            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        getStockList().then(r => setStock(r))
        getSupply().then(r => setSupply(r))
    }, [])

    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Обновление запущенно! Подождите 5 минут для обновления данных.
                </Alert>
            </Snackbar>
            <Button onClick={handleClick} style={{marginLeft: "20px", marginTop: "20px"}} color="warning">Обновить</Button>
            <Tabs aria-label="tabs" defaultValue={1}>
                <TabList
                    style={{flexDirection : "column"}}
                    variant="plain"
                    sx={{
                        '--ListItem-radius': '0px',
                        borderRadius: 0,
                        [`& .${tabClasses.root}`]: {
                            fontWeight: 'lg',
                            flex: 1,
                            bgcolor: 'background.body',
                            position: 'relative',
                            [`&.${tabClasses.selected}`]: {
                                color: 'primary.500',
                            },
                            [`&.${tabClasses.selected}:before`]: {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                bottom: -1,
                                width: '100%',
                                height: 2,
                                bgcolor: 'primary.400',
                            },
                            [`&.${tabClasses.focusVisible}`]: {
                                outlineOffset: '-3px',
                            },
                        },
                    }}
                >
                    {stock.map((e) => {
                        return (
                            <Tab value={e.id}>{e.name}</Tab>
                        )
                    })}
                </TabList>
                {stock.map(x => {
                    return (
                        <TabPanel value={x.id}>
                            <Table style={{width: "95%", marginTop: "20px", marginLeft: "30px"}} sx={{ '& thead th:nth-child(1)': { width: '20%' } }}>
                                <thead>
                                <tr>
                                    <th>Баркод</th>
                                    <th>Название</th>
                                    <th>Размер</th>
                                    <th>На Складе WB</th>
                                    <th>Догрузить</th>
                                </tr>
                                </thead>
                                <tbody>

                                {supply.filter(r => r.stock.number === x.number).map(a => {
                                    return (
                                        <tr>
                                            <td>{a.product.idProduct.idBarcode.barcode}</td>
                                            <td>{a.product.idProduct.idBarcode.idModel.name}</td>
                                            <td>{a.product.idProduct.idBarcode.idModel.idSize.number}</td>
                                            <td>{a.value}</td>
                                            <td>{a.loadAmount}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </TabPanel>
                    )
                })}
            </Tabs>
        </>
    )
}