import {useState} from "react";
import axios from "axios";
import {
    Alert,
    AlertTitle, Backdrop,
    Box,
    Button, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    TextField
} from "@mui/material";
import * as React from "react";
import {getIdShopByUser} from "../../util/API"

export const EditModel = () => {
    const [value, setValue] = useState();
    const [option, setOption] = useState('');
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState();
    const [snackbarError, setSnackbarError] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [id, setId] = useState();
    const [upload, setUpload] = useState();

    const handleChange = (event) => {
        setOption(event.target.value);
    };

    const handleClickOpen = (event, idShopProduct) => {
        setOpen(true);
        setId(idShopProduct);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const config = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Accept": "*/*",
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json"
            },
        };

        getIdShopByUser().then(idShop => {

            let body = {}

            switch (option) {
                case 10:
                    body = {"idShop": idShop, "barcode": value}
                    break;
                case 20:
                    body = {"idShop": idShop, "artcode": value}
                    break;
                case 30:
                    body = {"idShop": idShop, "name": value}
                    break;
            }

            axios.post("http://45.9.40.232:8080/api/v1/shop/product/find/edit", body, config)
                .then(response => {
                    let elements = [];
                    response.data.map(x => elements.push(x))
                    setTimeout(() => {
                        setLoading(false);
                        setSuccessMessage("Модели загружены")
                        setSnackbar(true);
                        setData(elements);
                    }, 1000)
                })
                .catch(err => {
                    setTimeout(() => {
                        setLoading(false);
                        setErrorMessage("Упс ошибка при соединении с сервером");
                        setSnackbarError(true);
                    }, 1000)
                })
        });
    }

    const handleEdit = (event) => {
        setOpen(false);
        setLoading(true);
        var bodyFormData = new FormData();
        bodyFormData.append('file', upload.target.files[0]);
        bodyFormData.append('name', String(id));
        axios({method: 'POST', url : "http://45.9.40.232:8080/api/v1/model/add/img/name", data : bodyFormData, responseType: 'blob', headers : {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Access-Control-Allow-Origin" : '*',
                'Content-Type': 'multipart/form-data',
            }})
            .then(response => {
                setTimeout(() => {
                    setLoading(false);
                    setSuccessMessage("Фотография загружена для модели " + id);
                    setSnackbar(true)
                }, 1000)
            })
            .catch(err => {
                setTimeout(() => {
                    setLoading(false)
                    setErrorMessage("Ошибка соединения с сервером");
                    setSnackbarError(true);
                }, 1000);
            })
    }

    const handleCloseSuccess = (event, reason) => {
        setSnackbar(false);
    };

    const handleCloseError = (event, reason) => {
        setSnackbarError(false);
    };

    return (
        <>
            <div className="remove-model-container">
                <div>
                    <h1>Изменение модели</h1>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="input-line">
                        <Box sx={{minWidth: 120}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Фильтр</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={option}
                                    label="Фильтр"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>Баркод</MenuItem>
                                    <MenuItem value={20}>Арткод</MenuItem>
                                    <MenuItem value={30}>Название</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <TextField value={value} onChange={(e) => setValue(e.target.value)} id="outlined-basic"
                                   label="Значение" variant="outlined"/>
                        <Button disabled={value === undefined || option === undefined} variant="outlined"
                                color="warning" type="submit">
                            Поиск
                        </Button>
                    </div>
                </form>
                <Alert severity="info">
                    <AlertTitle>Информация</AlertTitle>
                    Для изменения модели кликните по ней из списка ниже
                </Alert>
            </div>
            <div className="remove-model-variant">
                <div className="card-variant">
                    <div className="card-variant-element card-variant-header">
                        <h4 className="card-variant-element-text">Картинка</h4>
                        <h4 className="card-variant-element-text">Баркод</h4>
                        <h4 className="card-variant-element-text">Арткод</h4>
                        <h4 className="card-variant-element-text">Название</h4>
                        <h4 className="card-variant-element-text">Цвет</h4>
                        <h4 className="card-variant-element-text">Размер</h4>
                    </div>
                    {data.map((x, i) => {
                        return (
                            <div key={i} className="card-variant-element element"
                                 onClick={(e) => handleClickOpen(e, x.idProduct.idBarcode.idModel.name)}>
                                <span>{x.idProduct.idBarcode.idModel.img !== null ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 13L9 17L19 7" stroke="#3CFF2C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 18L18 6M6 6L18 18" stroke="#DA4052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                }</span>
                                <span>{x.idProduct.idBarcode.barcode}</span>
                                <span>{x.idProduct.idArtCode.artCode}</span>
                                <span>{x.idProduct.idBarcode.idModel.name}</span>
                                <span>{x.idProduct.idBarcode.idModel.idColor.colorName}</span>
                                <span>{x.idProduct.idBarcode.idModel.idSize.number}</span>
                            </div>
                        )
                    })}
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Выбор картинки"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Для загрузки выберите необходимое изображение.
                        <br/>
                        Название файла: {upload !== undefined ? upload.target.files[0].name : <></>}
                    </DialogContentText>
                    <div style={{display: "flex", justifyContent: "center", marginTop: "5px"}}>
                        <Button
                            style={{marginBottom: "0"}}
                            variant="contained"
                            color="secondary"
                            component="label"
                            sx={{mb: 2}}
                            onChange={(e) => setUpload(e)}
                        >
                            Выбрать
                            <input hidden accept="image/*" type="file"/>
                        </Button>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleClose}>Отмена</Button>
                    <Button variant="outlined" color="success" onClick={handleEdit}>
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <Snackbar open={snackbar} autoHideDuration={2000} onClose={handleCloseSuccess}>
                <Alert severity="success" onClose={handleCloseSuccess}>
                    <AlertTitle>Отлично</AlertTitle>
                    {successMessage} — <strong>Успех!</strong>
                </Alert>
            </Snackbar>
            <Snackbar open={snackbarError} autoHideDuration={2000} onClose={handleCloseError}>
                <Alert severity="error" onClose={handleCloseError}>
                    <AlertTitle>Ошибка!</AlertTitle>
                    {errorMessage} — <strong>Оёй!</strong>
                </Alert>
            </Snackbar>
        </>
    )
}