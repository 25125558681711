import {
    Alert,
    AlertTitle,
    Autocomplete,
    Backdrop,
    Button,
    CircularProgress,
    Paper,
    Snackbar,
    TextField
} from "@mui/material";
import Box from '@mui/material/Box';
import "./addShopProduct.css"
import {Form} from "react-bootstrap";
import {forwardRef, useEffect, useState} from "react";
import {getIdShopByUser} from "../../util/API";
import {getIdShop} from "../../util/Service";
import axios from "axios";
import * as React from "react";


export const AddShopProduct = () => {
    const [barcode, setBarcode] = useState();
    const [artcode, setArtcode] = useState();
    const [name, setName] = useState();
    const [color, setColor] = useState();
    const [size, setSize] = useState();

    const [sole, setSole] = useState();
    const [inputSole, setInputSole] = useState();
    const [optionsSole, setOptionsSole] = useState();

    const [snackbar, setSnackbar] = useState(false);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [snackbarError, setSnackbarError] = useState(false);


    useEffect(() => {
        const config = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Accept" : "*/*",
                "Access-Control-Allow-Origin" : '*',
                "Content-Type": "application/json"
            },
        };

        axios.get("http://45.9.40.232:8080/api/v1/sole/get/all", config).then(response => {
            setOptionsSole(response.data.map(x => x.soleName));
        })
    }, [optionsSole])

    const onSubmit = (e) => {
        e.preventDefault();
        let a = [...data];
        a.push({"barcode": barcode, "artcode": artcode, "name": name, "color": color, "size": size, "sole" : sole})
        setData(a)
    }

    const removeElement = (value) => {
        let a = [...data];
        a = a.filter(x => x.barcode !== value)
        setData(a)
    }

    const handleClose = (event, reason) => {
        setSnackbar(false);
    };

    const handleCloseError = (event, reason) => {
        setSnackbarError(false);
    };

    const saveElements = () => {
        const config = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Accept" : "*/*",
                "Access-Control-Allow-Origin" : '*',
                "Content-Type": "application/json"
            },
        };

        setOpen(true)
            let a = [...data]
            for (let element in a) {
                const data = {
                    "idShop": {
                        "id": getIdShop()
                    },
                    "idProduct": {
                        "idArtCode": {
                            "artCode": a[element].artcode
                        },
                        "idBarcode": {
                            "barcode": a[element].barcode,
                            "idModel": {
                                "name": a[element].name,
                                "img": "null",
                                "idSize": {
                                    "number": a[element].size
                                },
                                "idColor": {
                                    "colorName": a[element].color
                                },
                                "idSole" : {
                                    "soleName" : a[element].sole
                                }
                            }
                        }
                    }
                }

                axios.post("http://45.9.40.232:8080/api/v1/shop/add/product", data, config).then(response => {
                }).catch(err => {
                    setTimeout(() => {
                        setOpen(false);
                        setSnackbarError(true)
                    }, 1000)
                })
                    .finally(() => {
                        setTimeout(() => {
                            setOpen(false);
                            setData([]);
                            setSnackbar(true)
                        }, 1000)
                    })
            }
    }

    return (
        <>
            <Snackbar open={snackbar} autoHideDuration={2000} onClose={handleClose}>
                <Alert severity="success" onClose={handleClose} >
                    <AlertTitle>Отлично</AlertTitle>
                    Модели добавлены — <strong>Успех!</strong>
                </Alert>
            </Snackbar>
            <Snackbar open={snackbarError} autoHideDuration={2000} onClose={handleCloseError}>
                <Alert severity="error" onClose={handleCloseError}>
                    <AlertTitle>Ошибка!</AlertTitle>
                    Не удалось добавить модели — <strong>Оёй!</strong>
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        <div className="add-shop-product-container">
            <Box
                style = {{display: "flex",
                         justifyContent: "center"}}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '& .MuiTextField-root': { width: '40ch' },
                }}
            >
                <Form onSubmit={onSubmit}>
                    <div style={{display: "grid"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                        <h1>Добавить модель</h1>
                        </div>

                    <TextField
                        fullWidth
                        label="Бар-код"
                        value={barcode}
                        onChange={(e) => setBarcode(e.target.value)}
                        sx={{ m: 1, width: '40ch' }}
                    />

                        <TextField
                            fullWidth
                            label="Арткод"
                            value={artcode}
                            onChange={(e) => setArtcode(e.target.value)}
                            sx={{ m: 1, width: '40ch' }}
                        />

                        <TextField
                            fullWidth
                            label="Название"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ m: 1, width: '40ch' }}
                        />

                        <TextField
                            fullWidth
                            label="Цвет"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            sx={{ m: 1, width: '40ch' }}
                        />

                        <TextField
                            fullWidth
                            label="Размер"
                            value={size}
                            onChange={(e) => setSize(e.target.value)}
                            sx={{ m: 1, width: '40ch' }}
                        />

                        <Autocomplete
                            style={{m: 1, width: '40ch', padding: "8px"}}
                            value={sole}
                            onChange={(event, newValue) => {
                                setSole(newValue);
                            }}
                            inputValue={inputSole}
                            onInputChange={(event, newInputValue) => {
                                setInputSole(newInputValue);
                            }}
                            getOptionLabel={(option) => option}
                            id="controllable-states-demo"
                            options={optionsSole}
                            renderInput={(params) => <TextField {...params} label="Подошва"/>}
                        />
                        <br/>
                    <Button type="submit" variant="contained" style={{height: "50px"}}>Добавить</Button>
                        </div>
                </Form>
            </Box>
        </div>
            <br/>
            <div className="list-button">
                <Button  type="submit" variant="contained" color="success" onClick={saveElements}>Сохранить</Button>
            </div>


    <div className="list-product">

        {data.map(element =>
        <Paper className="list-product-paper" elevation={3}>
            <div className="card-body-product">
                <p>Баркод:{element.barcode}</p>
                <p>Арткод:{element.artcode}</p>
                <p>Название:{element.name}</p>
                <p>Цвет:{element.color}</p>
                <p>Размер:{element.size}</p>
                <p>Подошва:{element.sole}</p>
            </div>

            <Button variant="contained" color="error" onClick={() => removeElement(element.barcode)}>Удалить</Button>
        </Paper>
        )}
    </div>
    </>
    )
}