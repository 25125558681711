import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import {authService} from "../../util/API";

const Login = () => {
    const [login, setLogin] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();

    const onSubmit = (e) => {
        e.preventDefault();
        authService(login, password).then(response => {
            console.log(response)
            if (response !== "error") {
                localStorage.setItem("token", response);
                window.location.href = '/user/dashboard'
            } else {
                setError(true);
            }
        });
    }


    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={8}>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm onSubmit={onSubmit}>
                                        <h1>Авторизация</h1>
                                        <p className="text-medium-emphasis">Войдите в свой аккаунт</p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupText>
                                                <CIcon icon={cilUser} />
                                            </CInputGroupText>
                                            <CFormInput
                                                placeholder="Username"
                                                autoComplete="username"
                                                value={login}
                                                onChange={(e) => setLogin(e.target.value)} />
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupText>
                                                <CIcon icon={cilLockLocked} />
                                            </CInputGroupText>
                                            <CFormInput
                                                type="password"
                                                placeholder="Password"
                                                autoComplete="current-password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs={6}>
                                                <CButton type="submit" color="primary" className="px-5">
                                                    Войти
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                            <CCard className="text-white bg-primary py-5" style={{ width: '50%' }}>
                                <CCardBody className="text-center">
                                    <div>
                                        <h2>Toto Rino Corporation</h2>
                                        <p>
                                            Это панель управления Toto Rino Dashboard, а также формирование отчетов. Мониторинг статистики и анализ данных.
                                        </p>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default Login
