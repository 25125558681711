import {TextField, Typography} from "@mui/material";
import {Button} from "@mui/joy";
import {useState} from "react";
import axios from "axios";
import {API_URL, config} from "../../../util/API";
import {getIdShop} from "../../../util/Service";

export const AmountTraking = ({sizes, artcode}) => {

    const [followBody, setFollowBody] = useState(sizes.map(x => {return ({"size" : x, "value" : 0})}));

    const handleFollowBody = (value, size) => {
        let state = [...followBody];
        state.map(x => {
          if (x.size === size) {
              x.value = value;
          }
      })
      setFollowBody(state);
    }

    const save = () => {
        let body = []
        for (let element of followBody) {
            body.push({
                "size" : {
                    "number" : element.size
                },
                "artcode" : {
                    "artcode" : artcode,
                    "shop" : {
                        "id" : getIdShop()
                    }
                },
                "value" : element.value
            })
        }
        axios.post(API_URL + "/follow", body, config).then(() => {
            window.location.reload();
        })
    }

    return (
        <div>
            <Typography  variant="h5" gutterBottom>
                Минимальное количество
            </Typography>
            {sizes.map((x, i) => {
                return (
                    <TextField key={i} style={{marginBottom : "10px"}} onChange={(e) => handleFollowBody(e.target.value, x)} sx={{width : "100%"}} className="outlined-basic" label={x} variant="outlined" />
                )
            })}
            <Button onClick={save} sx={{width : "100%", marginTop : "20px"}} color="success">Сохранить</Button>
        </div>
    )
}