import React, {createContext, useState} from "react";
import {UserDashboard} from "./components/UserDashboard";
import {Routes, Route, Navigate} from 'react-router-dom';
import {AddRequest} from "./components/AddRequest";
import {RequestsPage} from "./components/RequestsPage";
import {ProductCards} from "./components/ProductCards";
import {AddShopProduct} from "./components/AddShopProduct";
import {RemoveModel} from "./components/RemoveModel";
import {AddRequestCompany} from "./components/AddRequestCompany";
import {RequestsPageCompany} from "./components/RequestPageCompany";
import Login from "./components/AuthPage";
import {EditModel} from "./components/EditModel";
import {FollowManager} from "./components/FollowManager";
import {FollowList} from "./components/FollowList";
import {MainPage} from "./components/Follow/MainPage";


function App() {
    return (
        <Routes>
            <Route path="sign-in" element={<Login/>}/>
            {localStorage.getItem("token") !== null ?
            <Route path="user/dashboard" element={<UserDashboard/>}>
                <Route path="requests" element={<RequestsPage/>} />
                <Route path="add" element={<AddRequest/>} />
                <Route path="list" element={<ProductCards/>} />
                <Route path="product" element={<AddShopProduct  />} />
                <Route path="remove" element={<RemoveModel/>} />
                <Route path="company/add" element={<AddRequestCompany/>} />
                <Route path="company/request" element={<RequestsPageCompany/>} />
                <Route path="follow/manager" element={<MainPage/>} />
                <Route path="follow/list" element={<FollowList/>} />
                <Route path="img" element={<EditModel/>} />
            </Route>
            :  <Route
                    path=""
                    element={<Navigate to="/sign-in" replace />}
                />}
            <Route
                path=""
                element={<Navigate to="/user/dashboard" replace />}
            />}
            <Route
                path="*"
                element={<Navigate to="/sign-in" replace />}
            />
        </Routes>
    );
}

export default App;
