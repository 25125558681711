import {
    CButton,
    CCard,
    CCardBody,
    CCardImage,
    CCardText,
    CCardTitle,
    CCol,
    CContainer,
    CPagination, CPaginationItem,
    CRow
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {getProductList} from "../../util/API";
import "./productCard.css"
import {Pagination, Stack} from "@mui/material";

export const ProductCards = () => {

    const [products, setProducts] = useState();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        getProductList(page).then(
            response => {
                setTotalPages(response.totalPages)
                setProducts(response.content.map(product =>
                    <CCard style={{width: '18rem'}} className="product-card">
                        <CCardImage component="svg" orientation="top" width="100%" height="162"
                                    xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder"
                                    preserveAspectRatio="xMidYMid slice" focusable="false">
                            <title>Placeholder</title>
                            <rect width="100%" height="100%" fill="#868e96"></rect>
                        </CCardImage>
                        <CCardBody>
                            <CCardTitle>{product.idProduct.idBarcode.idModel.name}</CCardTitle>
                            <CCardText>
                                <ul>
                                    <li>Бар-код: {product.idProduct.idBarcode.barcode}</li>
                                    <li>Арт-код: {product.idProduct.idArtCode.artCode}</li>
                                    <li>Размер: {product.idProduct.idBarcode.idModel.idSize.number}</li>
                                    <li>Цвет: {product.idProduct.idBarcode.idModel.idColor.colorName}</li>
                                </ul>
                            </CCardText>
                            <CButton href="#">Просмотр</CButton>
                        </CCardBody>
                    </CCard>));
            }
        )
    })

    return (
        <>
            <div className="product-cards">
                {products}
            </div>
            <div className="pagination">
                <Stack spacing={3}>
                    <Pagination count={totalPages} page={page} onChange={handleChange} color="primary" />
                </Stack>
            </div>
        </>
    )
}