import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import {
    Alert,
    AlertTitle,
    Autocomplete,
    Backdrop,
    Button,
    CircularProgress,
    InputLabel, MenuItem, Select,
    Snackbar,
    TextField
} from "@mui/material";
import AddLinkIcon from "@mui/icons-material/AddLink";
import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect, useState} from "react";
import "./addRequestCompany.css";
import "../RemoveModel/removeModel.css";
import axios from "axios";
import {getIdShop, getIdUser} from "../../util/Service";
import SendIcon from "@mui/icons-material/Send";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const config = {
    headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        "Accept" : "*/*",
        "Access-Control-Allow-Origin" : '*',
        "Content-Type": "application/json"
    },
};




export const AddRequestCompany = ({inputRows, idRequest, inputSupply}) => {
    const [name, setName] = useState(null);
    const [inputName, setInputName] = useState("");
    const [optionsName, setOptionsName] = useState(["Загрузка"]);

    const [size, setSize] = useState(null);
    const [optionsSize, setOptionsSize] = useState([]);
    const [inputSize, setInputSize] = useState("");

    const [supply, setSupply] = useState();

    const [snackbarError, setSnackbarError] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [amount, setAmount] = useState(1);

    const [loading, setLoading] = useState(false);

    const [rows, setRows] = useState([]);

    const [selected, setSelected] = useState([]);

    const [input, setInput] = useState(false);

    const [sumAmount, setSumAmount] = useState();



    useEffect(() => {

        axios.get("http://45.9.40.232:8080/api/v1/shop/products/names/" + getIdUser(), config)
            .then(response => {
                if (response.status === 200) {
                    setOptionsName(response.data);
                }
        })
        if (inputRows !== undefined && !input) {
            setInput(true);
            setRows(inputRows);
            setSupply(inputSupply)
        }
        if (rows.length > 0) {
            let sum = 0;
            rows.map(x => {sum += x.amount});
            setSumAmount(sum)
        }
    }, [rows])

    const onChangeName = (value) => {
        setName(value);
        axios.get("http://45.9.40.232:8080/api/v1/product/get/size/" + value + "/" + getIdShop(), config)
            .then(response => {
                if (response.status === 200) {
                    setOptionsSize(response.data.sort((x, y) => { return x > y ? 1 : -1}));
                }
            })
    }

    const onChangeSize = (value) => {
        setSize(value)
    }

    const handleCloseSuccess = (event, reason) => {
        setSnackbar(false);
    };

    const handleCloseError = (event, reason) => {
        setSnackbarError(false);
    };

    const onSelect = (id) => {
        let tempSelected = [...selected];
        if (tempSelected.filter(x => x === id).length > 0) {
            tempSelected = tempSelected.filter(x => x !== id);
        }
        else {
            tempSelected.push(id);
        }
        setSelected(tempSelected);
    }

    const onRemove = () => {
        let tempRows = [...rows];
        selected.map(id => {tempRows = tempRows.filter(row => row.id !== id)})
        setSelected([]);
        setRows(tempRows);
    }

    const addElement = (value, allSizes) => {
        let tempRows = [...rows];
        let optionalElement = tempRows.filter(x => x.id === value.id);
        if (optionalElement.length > 0) {
            let element = tempRows[tempRows.findIndex(x => x.id === value.id)];
            if (allSizes && element.size === 41 || allSizes && element.size === 42) {
                tempRows[tempRows.findIndex(x => x.id === value.id)].amount += parseInt(amount * 2);
            }
            else {
                tempRows[tempRows.findIndex(x => x.id === value.id)].amount += parseInt(amount);
            }
        }
        else {
            let data = {"id" : null, "barcode" : null, "artcode" : null, "name" : null, "color": null, "size": null, "sole" : null, "amount": null};
            data.id = value.id;
            data.barcode = value.idBarcode.barcode;
            data.artcode = value.idArtCode.artCode;
            data.name = value.idBarcode.idModel.name;
            data.color = value.idBarcode.idModel.idColor.colorName;
            data.size = value.idBarcode.idModel.idSize.number;
            data.sole = value.idBarcode.idModel.idSole.soleName;
            if (allSizes && data.size === 41 || allSizes && data.size === 42) {
                data.amount = parseInt(amount * 2);
            } else {
                data.amount = parseInt(amount);
            }
            setRows(current => [...current, data]);
        }
    }

    const addSize = () => {
        axios.get("http://45.9.40.232:8080/api/v1/product/get/" + name + "/" + size + "/" + getIdShop(), config)
            .then(response => {
                if (response.status === 200) {
                    addElement(response.data[0], false);
                }
            })
    }

    const onAddSizes = () => {
        axios.get("http://45.9.40.232:8080/api/v1/product/" + name + "/" + getIdShop() , config)
            .then(response => {
                if (response.status === 200) {
                    let responseData = response.data;
                    for (let element in responseData) {
                        addElement(responseData[element], true);
                    }
                }
            })
    }

    const onSorting = () => {
        let tempRows = [...rows];
        setRows(tempRows.sort(function(a, b) {
            if (a.name === b.name){
                return a.size > b.size ? 1 : -1;
            }
            return a.name > b.name ? 1 : -1;
        }))
    }

    const saveRequest = (id) => {
        let data = [];
        let tempRows = [...rows];
        for (let element in tempRows) {
            let body =
                {
                    "amount": null,
                    "idShopProduct": {
                        "idShop": {
                            "id": getIdShop()
                        },
                        "idProduct": {
                            "idArtCode": {
                                "artCode": null
                            },
                            "idBarcode": {
                                "barcode": null,
                                "idModel": {
                                    "name": null,
                                    "img": null,
                                    "idSize": {
                                        "number": null
                                    },
                                    "idColor": {
                                        "colorName": null
                                    },
                                    "idSole" : {
                                        "soleName" : null
                                    }
                                }
                            }
                        }
                    },
                    "idRequestCompany": {
                        "id": null,
                        "idUser": {
                            "id": getIdUser()
                        }
                    }
                }
            let r = idRequest !== undefined ? idRequest : id;
            body["idShopProduct"]["idProduct"]["idBarcode"]["barcode"] = tempRows[element].barcode;
            body["idShopProduct"]["idProduct"]["idArtCode"]["artCode"] = tempRows[element].artcode;
            body["idShopProduct"]["idProduct"]["idBarcode"]["idModel"]["idColor"]["colorName"] = tempRows[element].color;
            body["idShopProduct"]["idProduct"]["idBarcode"]["idModel"]["idSize"]["number"] = tempRows[element].size;
            body["idShopProduct"]["idProduct"]["idBarcode"]["idModel"]["idSole"]["soleName"] = tempRows[element].sole;
            body["idShopProduct"]["idProduct"]["idBarcode"]["idModel"]["name"] = tempRows[element].name;
            body["amount"] = tempRows[element].amount;
            body["idRequestCompany"]["id"] = r;
            data.push(body);
        }

        axios.post('http://45.9.40.232:8080/api/v1/request/list/company/add', data, config)
            .then(response => {
                setTimeout(() => {
                    setSuccessMessage("Заявка успешно сохранена")
                    setSnackbar(true);
                }, 1000)
            })
            .catch(err => {
                setTimeout(() => {
                    setErrorMessage("Ошибка при соединении сервером")
                    setSnackbarError(true);
                }, 1000)
            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000)
            })
    }

    const copyRequest = (id) => {
        axios.get('http://45.9.40.232:8080/api/v1/request/company/' + id, config)
            .then(response => {
                let request = response.data;

                let requestBody = {
                    "number" : request.id,
                    "name" : request.supply,
                    "status" : "Сборка",
                    "date" : request.date
                }
                axios.post("https://api.totorinostat.ru/bid/save", requestBody);
            } )
    }

    const onSave = () => {
        setLoading(true);

        let date = new Date();
        let requestBody = {
            "supply" : supply,
            "date": `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`,
            "idUser": {
                "id": getIdUser()
            }}

        if (idRequest !== undefined) {
            saveRequest();
            copyRequest(idRequest);
        } else {
            axios.post("http://45.9.40.232:8080/api/v1/request/company/add", requestBody, config).then(response => {
                saveRequest(response.data);
                copyRequest(response.data);
            })
        }
    }

    return (
        <>
            <Box sx={{flexGrow: 1}} style={{margin: "30px"}}>
                <Grid container spacing={2}>
                    <Grid xs>
                        <Item>
                            <Autocomplete
                                value={name}
                                onChange={(event, newValue) => {
                                    onChangeName(newValue);
                                }}
                                inputValue={inputName}
                                onInputChange={(event, newInputValue) => {
                                    setInputName(newInputValue);
                                }}
                                id="controllable-states-demo"
                                options={optionsName}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                getOptionLabel={(option) => option.toString()}
                                renderInput={(params) => <TextField {...params} label="Название"/>}
                            />
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <Autocomplete
                                disabled={name === null}
                                value={size}
                                onChange={(event, newValue) => {
                                    onChangeSize(newValue);
                                }}
                                inputValue={inputSize}
                                onInputChange={(event, newInputValue) => {
                                    setInputSize(newInputValue);
                                }}
                                id="controllable-states-demo"
                                options={optionsSize}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                getOptionLabel={(option) => option.toString()}
                                renderInput={(params) => <TextField {...params} label="Размер"/>}
                            />
                        </Item>
                        <Button disabled={name === null} style={{marginTop: "10px", width: "100%"}} variant="contained" endIcon={<AddLinkIcon/>} onClick={onAddSizes}>
                            Модельный ряд
                        </Button>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField
                                style={{width: "100%"}}
                                id="outlined"
                                label="Количество"
                                onChange={(e) => setAmount(e.target.value)}
                                value={amount}
                                defaultValue="1"
                            />
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <TextField
                                style={{width: "100%"}}
                                id="outlined"
                                label="Поставка"
                                onChange={(e) => setSupply(e.target.value)}
                                value={supply}
                                defaultValue=""
                            />
                        </Item>
                    </Grid>

                </Grid>
            </Box>
            <div style={{marginLeft: "20px", marginBottom: "20px", display: "flex", gap: "10px"}}>
                <Button variant="contained" color="success" endIcon={<SendIcon/>} onClick={addSize}>
                    Добавить
                </Button>
                <Button
                    disabled={supply === undefined}
                    variant="contained"
                    color="info"
                    endIcon={<BookmarkAddIcon/>}
                    onClick={onSave}
                >
                    Сохранить
                </Button>
                <Button disabled={selected.length === 0} variant="contained" color="error" endIcon={<PlaylistRemoveIcon/>} onClick={onRemove}>
                    Удалить
                </Button>
                <Button disabled={rows.length === 0} variant="contained" color="warning" endIcon={<PlaylistRemoveIcon/>} onClick={onSorting}>
                    Отсортировать
                </Button>
            </div>
            <div className="remove-model-variant">
                <div className="card-variant">
                    <div className="card-variant-element add-request-element card-variant-header add-request-company-header">
                        <h4 className="card-variant-element-text">Номер</h4>
                        <h4 className="card-variant-element-text">Название</h4>
                        <h4 className="card-variant-element-text">Цвет</h4>
                        <h4 className="card-variant-element-text">Размер</h4>
                        <h4 className="card-variant-element-text">Подошва</h4>
                        <h4 className="card-variant-element-text">Количество {sumAmount}</h4>
                    </div>
                    {rows.map((element, index) => {
                        return (
                            <div key={index}
                                 className={selected.filter(x => x === element.id).length > 0 ?
                                     "add-request-element card-variant-element add-request-company-element  element-selected" :
                                     "add-request-element card-variant-element add-request-company-element  element" }
                                 onClick={() => onSelect(element.id)}>
                                <span>{element.id}</span>
                                <span>{element.name}</span>
                                <span>{element.color}</span>
                                <span>{element.size}</span>
                                <span>{element.sole}</span>
                                <span>{element.amount}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar open={snackbar} autoHideDuration={2000} onClose={handleCloseSuccess}>
                <Alert severity="success" onClose={handleCloseSuccess} >
                    <AlertTitle>Отлично</AlertTitle>
                    {successMessage} — <strong>Успех!</strong>
                </Alert>
            </Snackbar>
            <Snackbar open={snackbarError} autoHideDuration={2000} onClose={handleCloseError}>
                <Alert severity="error" onClose={handleCloseError}>
                    <AlertTitle>Ошибка!</AlertTitle>
                    {errorMessage} — <strong>Оёй!</strong>
                </Alert>
            </Snackbar>
        </>
    )
}