import {CButton, CContainer, CNavbar, CNavbarBrand} from "@coreui/react";
import "./navbar.css"

export const NavigationBar = () => {

    const onClick = () => {
      localStorage.removeItem("token");
      window.location.reload();
    }

    return (
        <CNavbar colorScheme="light" className="bg-light">
            <CContainer fluid>
                <CNavbarBrand href="#">Toto Rino Corporation Dashboard</CNavbarBrand>
                <CButton className="d-flex" color="danger" onClick={() => onClick()}>
                    Выход
                </CButton>
            </CContainer>
        </CNavbar>
    )
}