import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import {
    Alert,
    AlertTitle,
    Autocomplete,
    Backdrop,
    Button,
    CircularProgress,
    InputLabel, MenuItem, Select,
    Snackbar,
    TextField
} from "@mui/material";
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import {useEffect, useState} from "react";
import {
    getProducts,
    getSizesByName,
    getDataFromNameAndSizeProduct,
    saveRequest,
    saveRequestList,
    getDataFromNameProduct,
    removeRequestList, getIdShopByUser
} from "../../util/API";
import SendIcon from '@mui/icons-material/Send';
import AddLinkIcon from '@mui/icons-material/AddLink';
import {DataGrid} from "@mui/x-data-grid";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import axios from "axios";
import {getStockList} from "../../util/API";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const AddRequest = ({rowsInput, requestIdInput, ...setEdit}) => {
    const [rows, setRows] = useState([]);
    const [options, setOptions] = useState([]);
    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');
    const [size, setSize] = useState();
    const [optionsSize, setOptionsSize] = useState();
    const [color, setColor] = useState("Белый");
    const [barcode, setBarcode] = useState();
    const [artcode, setArtcode] = useState();
    const [inputSize, setInputSize] = useState();
    const [requestId, setRequestId] = useState();
    const [amount, setAmount] = useState(1);
    const [id, setId] = useState(1);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [idShop, setIdShop] = useState();
    const [open, setOpen] = useState(false);
    const [snackbarError, setSnackbarError] = useState(false);
    const [snackbar, setSnackbar] = useState(false);

    const [barcodes, setBarcodes] = useState([]);
    const [stock, setStock] = React.useState('');
    const [stockList, setStockList] = useState([]);

    const [update, setUpdate] = useState(false);

    const handleChange = (event) => {
        setStock(event.target.value);
    };

    useEffect(() => {
        getProducts().then(data => {
            setOptions(data);
            setUpdate(!update);
        })
        if (rowsInput !== undefined && rows.length === 0) {
            setRows(rowsInput);
            setUpdate(!update);
        }
        getStockList().then(response => {
            setStockList(response)
            setUpdate(!update);
        })
    }, [update])

    const columns = [
        {field: 'barcode', headerName: 'Бар-код', width: 150},
        {field: 'artcode', headerName: 'Арткод', width: 150},
        {field: 'name', headerName: 'Название', width: 300},
        {field: 'size', headerName: 'Размер', width: 150},
        {field: 'color', headerName: 'Цвет', width: 150},
        {field: 'amount', headerName: 'Количество', width: 150},
    ]

    const handleClose = (event, reason) => {
        setSnackbar(false);
    };

    const handleCloseError = (event, reason) => {
        setSnackbarError(false);
    };


    const onChangeName = (value) => {
        setValue(value);
        getIdShopByUser().then(x => {
            getSizesByName(value, x).then(data => {
                setOptionsSize(data.map(data => String(data)));
            })
                .then(response => {
                    let array = [...optionsSize];
                        getIdShopByUser().then(response => {
                            getDataFromNameAndSizeProduct(value, size, response).then(x => {
                                setId(x[0].id)
                                setColor(x[0].idBarcode.idModel.idColor.colorName)
                                setArtcode(x[0].idArtCode.artCode)
                                setBarcode(x[0].idBarcode.barcode)
                            })
                        })
                })
        })
    }

    const onChangeSize = (data) => {
        setSize(data);
        getIdShopByUser().then(response => {
            getDataFromNameAndSizeProduct(value, data, response).then(x => {
                setId(x[0].id)
                setColor(x[0].idBarcode.idModel.idColor.colorName)
                setArtcode(x[0].idArtCode.artCode)
                setBarcode(x[0].idBarcode.barcode)
            })
        })
    }

    const onClick = (e) => {
        if (barcode !== undefined) {
            if (rows.filter(row => row.barcode === barcode).length > 0) {
                let b = [...rows];
                let v = b[b.findIndex(row => row.barcode === barcode)];
                v.size === 41 || v.size === 42 ? v.amount =
                    parseInt(v.amount) + parseInt(amount) : v.amount = parseInt(v.amount) + parseInt(amount);
                b[b.findIndex(row => row.barcode === barcode)] = v;
                setRows(b);
            }
            else {
                setRows([{
                    "id": id,
                    "barcode": barcode,
                    "artcode": artcode,
                    "name": value,
                    "size": size,
                    "color": color,
                    "amount": amount
                }, ...rows]);
            }
        }
    }

    const addElement = (element) => {
        let a = rows.filter(row => row.barcode === element.barcode);
        if (a.length > 0) {
            let b = [...rows];
            let v = b[b.findIndex(row => row.barcode === element.barcode)];
            v.size === 41 || v.size === 42 ? v.amount =
              v.amount + amount * 2 : v.amount = parseInt(v.amount) + parseInt(amount);
            b[b.findIndex(row => row.barcode === element.barcode)] = v;
            setRows(b);
        } else {
            setRows(current => [...current, element]);
        }
    }

    const onSave = () => {
        if (rows.length !== 0) {
            setOpen(true);
                let date = new Date();
                if (requestIdInput === undefined) {
                    saveRequest(`${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`, stock).then(data => {
                        setRequestId(data.id)
                        rows.map(x => {
                            saveRequestList(data.id, x)
                        });
                        setTimeout(() => {
                            setOpen(false);
                            setSnackbar(true);
                            setRows([])
                        }, 2000)
                    }).catch(err => {
                        setTimeout(() => {
                            setOpen(false);
                            setSnackbarError(true);
                        }, 2000)

                    }).finally(() => {
                        setTimeout(() => {
                            setOpen(false);
                            setSnackbar(true);
                            setRows([])
                        }, 2000)
                    })
                    if (setEdit !== undefined) {
                        setEdit(false);
                    }
                } else {
                    rows.map(x => {
                        saveRequestList(requestIdInput, x)
                    });
                    setTimeout(() => {
                        setOpen(false);
                        setSnackbar(true);
                        setRows([])
                    }, 2000)
                }
        }
        else {
            setSnackbarError(true);
        }
    }

    const allSize = (e) => {
        if (value !== undefined && amount !== "") {
            getIdShopByUser().then(response => {
                getDataFromNameProduct(value, response).then(x => {
                    let a = x.map(x => x);
                    // a = a.filter(function(element) {
                    //     let temp = rows.filter(row => row.id === element.id);
                    //     if (temp.length === 0) return true;
                    // })
                    x.map(y => {
                        addElement({
                            "id": y.id,
                            "barcode": y.idBarcode.barcode,
                            "artcode": y.idArtCode.artCode,
                            "name": y.idBarcode.idModel.name,
                            "size": y.idBarcode.idModel.idSize.number,
                            "color": y.idBarcode.idModel.idColor.colorName,
                            "amount": y.idBarcode.idModel.idSize.number === 41 || y.idBarcode.idModel.idSize.number === 42 ? amount * 2 : amount
                        })
                    })
                })
            })
        }
    }

    const rowSelect = (e) => {
        setSelectedRows(e);
    }

    const removeItem = () => {
        let elements = rows.filter(function(x) {
            return selectedRows.filter(y => y === x.id).length === 0;
        });

        if (requestIdInput !== undefined) {
            let deleted = rows.filter(function(x) {
                return selectedRows.filter(y => y === x.id).length !== 0;
            });
            deleted.map(x => removeRequestList(requestIdInput, x));
        }
        setRows(elements.length > 0 ? elements : []);
    }

    const sortingList = () => {
        let a = [...rows];
        setRows(a.sort(function(a, b) {
           if (a.name === b.name){
               return a.size > b.size ? 1 : -1;
           }
            return a.name > b.name ? 1 : -1;
        }))
    }


    return (
        <div>
            <Snackbar open={snackbar} autoHideDuration={2000} onClose={handleClose}>
                <Alert severity="success" onClose={handleClose} >
                    <AlertTitle>Отлично</AlertTitle>
                    Заявка сохранена — <strong>Успех!</strong>
                </Alert>
            </Snackbar>
            <Snackbar open={snackbarError} autoHideDuration={2000} onClose={handleCloseError}>
                <Alert severity="error" onClose={handleCloseError}>
                    <AlertTitle>Ошибка!</AlertTitle>
                    Не удалось сохранить заявку. Видимо она пустая или сервер недоступен — <strong>Оёй!</strong>
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{flexGrow: 1}} style={{margin: "30px"}}>
                <Grid container spacing={2}>
                    <Grid xs={2}>
                        <Item>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Баркод"
                                value={barcode}
                                defaultValue="Баркод"
                            />
                        </Item>
                    </Grid>
                    <Grid xs={2}>
                        <Item>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Арткод"
                                value={artcode}
                                defaultValue="Арткод"
                            />
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <Autocomplete
                                value={value}
                                onChange={(event, newValue) => {
                                    onChangeName(newValue);
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                getOptionLabel={(option) => option}
                                id="controllable-states-demo"
                                options={options}
                                renderInput={(params) => <TextField {...params} label="Название"/>}
                            />
                        </Item>
                    </Grid>
                    <Grid xs>
                        <Item>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                value={stock}
                                id="demo-simple-select"
                                label="Склад"
                                onChange={handleChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {stockList.map(x => {
                                    return (
                                        <MenuItem value={x.id}>{x.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Item>
                    </Grid>
                    <Grid xs={2}>
                        <Item>
                            <Autocomplete
                                disabled={value === undefined}
                                value={size}
                                onChange={(event, newValue) => {
                                    onChangeSize(newValue);
                                }}
                                inputValue={inputSize}
                                onInputChange={(event, newInputValue) => {
                                    setInputSize(newInputValue);
                                }}
                                getOptionLabel={(option) => option}
                                id="controllable-states-demo"
                                options={optionsSize}
                                renderInput={(params) => <TextField {...params} label="Размер"/>}
                            />
                        </Item>
                        <Button
                            style={{marginTop: "10px", width: "100%"}}
                            variant="contained"
                            endIcon={<AddLinkIcon/>}
                            onClick={(e) => allSize(e)}
                        >
                            Весь модельный ряд
                        </Button>
                    </Grid>
                    <Grid xs={1}>
                        <Item>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Цвет"
                                value={color}
                                defaultValue="Цвет"
                            />
                        </Item>
                    </Grid>
                    <Grid xs={1}>
                        <Item>
                            <TextField
                                id="outlined"
                                label="Количество"
                                onChange={(e) => setAmount(e.target.value)}
                                value={amount}
                                defaultValue="1"
                            />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            <div style={{marginLeft: "20px", marginBottom: "20px", display: "flex", gap: "10px"}}>
                <Button
                    variant="contained"
                    color="success"
                    endIcon={<SendIcon/>}
                    onClick={(e) => onClick(e)}
                >
                    Добавить
                </Button>
                <Button
                    variant="contained"
                    color="info"
                    endIcon={<BookmarkAddIcon/>}
                    onClick={(e) => onSave(e)}
                >
                    Сохранить
                </Button>
                <Button
                    disabled={selectedRows.length === 0}
                    variant="contained"
                    color="error"
                    endIcon={<PlaylistRemoveIcon/>}
                    onClick={(e) => removeItem(e)}
                >
                    Удалить
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  endIcon={<PlaylistRemoveIcon/>}
                  onClick={() => sortingList()}
                >
                    Отсортировать
                </Button>
            </div>
            <div style={{height: 670, width: '100%'}}>
                <DataGrid
                    checkboxSelection
                    rows={rows}
                    columns={columns}
                    pageSize={100}
                    rowHeight={35}
                    getRowId={(x) => x.id}
                    onSelectionModelChange={(selection) => {rowSelect(selection)}}
                />
            </div>
        </div>
    );
}
