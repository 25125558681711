import * as React from 'react';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {forwardRef, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {getRequests, downloadFile, getRequestListByRequestId, getNameShop} from "../../util/API";
import {
    Alert,
    AlertTitle,
    Backdrop,
    Button,
    CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, MenuItem,
    Popover, Select,
    Snackbar,
    TextField
} from "@mui/material";
import {AddRequest} from "../AddRequest";
import {Link} from "@mui/joy";
import axios from "axios";
import {getIdShop} from "../../util/Service";

const columns = [
    {field: 'id', headerName: 'Номер', width: 150},
    {field: 'date', headerName: 'Дата', width: 150},
    {field: 'productCount', headerName: 'Количество пар', width: 200},
]

function dateToYMD(date) {
    var d = date.getDate();
    var m = date.getMonth() + 1; //Month from 0 to 11
    var y = date.getFullYear();
    return '' + (d <= 9 ? '0' + d : d) + '.' + (m<=9 ? '0' + m : m) + '.' + y;
}

export const RequestsPage = () => {
    const [rows, setRows] = useState({});
    const [checkboxSelection, setCheckboxSelection] = React.useState(true);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [edit, setEdit] = useState(false);
    const [inputRows, setInputRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [snackbarError, setSnackbarError] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openXml, setOpenXml] = useState(false)
    const [date, setDate] = useState(new Date());
    const [cises, setCises] = useState();
    const [xmlOzon, setXmlOzon] = useState(false);
    const [stockName, setStockName] = useState("");
    const [uniqueNumber, setUniqueNumber] = useState("");
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        getRequests().then(data => {
            let elements = [];
            data.map(x => elements.push({"id": x.id, "date": x.date, "productCount": x.amount}));
            elements.sort(function(a, b) {
                return b.id - a.id;
            });
            setRows(elements);
            setUpdate(true)
        })
    }, [update])

    const rowSelect = (e) => {
        const selectedIDs = new Set(e);
        const selectedRows = rows.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRows(selectedRows);
    }

    const downloadFileShkHandler = (requestId) => {
        if (selectedRows.length > 1) {
            setErrorMessage("Выберете одну заявку")
            setSnackbarError(true);
            return;
        }
        setOpenDialog(false);
        const data = {"products" : value.split("\n").filter(x => x !== ''), "idRequest": requestId[0].id}
        const config = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Accept" : "*/*",
                "Access-Control-Allow-Origin" : '*',
                "Content-Type" : "application/json"
            },
            body: JSON.stringify(data)
        };
        setOpenLoading(true);
        fetch('http://45.9.40.232:8080/wb/box/new', config)
            .then(response => {
                if (response.status === 400) {
                    setTimeout(() => {
                        setOpenLoading(false);
                        setErrorMessage("Упс ошибка в генерации файла. Проверьте количество шк")
                        setSnackbarError(true);
                    }, 2000)
                } else {
                    setTimeout(() => {
                        setOpenLoading(false);
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = "wb-korobi-" + String(requestId[0].id) + '.xlsx';
                            a.click();
                            setSuccessMessage("ШК-коробы сгенерированы")
                            setSnackbar(true);
                        })
                    },2000)
                }
            })
            .catch(err => {
               errorConnection();
            })
    }

    const errorConnection = () => {
        setTimeout(() => {
            setOpenLoading(false);
            setErrorMessage("Упс ошибка при соединении с сервером")
            setSnackbarError(true);
        }, 2000)
    }

    const downloadFileHandler = (requestId) => {
        if (selectedRows.length > 1) {
            setErrorMessage("Выберете одну заявку")
            setSnackbarError(true);
            return;
        }
        const config = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Accept" : "*/*",
                "Access-Control-Allow-Origin" : '*'
            },
        };
        fetch('http://45.9.40.232:8080/api/v1/file/' + requestId[0].id, config)
            .then(response => {

                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = String(requestId[0].id) + '.xlsx';
                    a.click();
                });
            })
            .catch(error => errorConnection())
    }

    const downloadFileWBHandler = (requestId) => {
        if (selectedRows.length > 1) {
            setErrorMessage("Выберете одну заявку")
            setSnackbarError(true);
            return;
        }
        const config = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Accept" : "*/*",
                "Access-Control-Allow-Origin" : '*'
            },
        };
        fetch('http://45.9.40.232:8080/api/v1/file/wb/' + requestId[0].id, config)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "shablon-zakaza-" + String(requestId[0].id) + '.xlsx';
                    a.click();
                });
            });
    }

    const downloadFileOZONHandler = (requestId) => {
        const config = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Accept" : "*/*",
                "Access-Control-Allow-Origin" : '*'
            }

        };
        fetch('http://45.9.40.232:8080/api/v1/ozon/' + requestId[0].id, config)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "shablon-zakaza-ozon-" + String(requestId[0].id) + '.xlsx';
                    a.click();
                });
            });

    }

     const editHandler = () => {
        let elements = [];
        if (selectedRows.length > 1) {
            setErrorMessage("Выберете одну заявку")
            setSnackbarError(true);
        } else {
            getRequestListByRequestId(selectedRows[0].id).then(data => {
                data.map(x => {
                    elements.push({
                        "id": x.id,
                        "barcode": x.idShopProduct.idProduct.idBarcode.barcode,
                        "artcode": x.idShopProduct.idProduct.idArtCode.artCode,
                        "name": x.idShopProduct.idProduct.idBarcode.idModel.name,
                        "size": x.idShopProduct.idProduct.idBarcode.idModel.idSize.number,
                        "color": x.idShopProduct.idProduct.idBarcode.idModel.idColor.colorName,
                        "amount": x.amount
                    });
                })
                setInputRows(elements);
            })
            if (!loading) {
                setEdit(true);
            }
        }
     }
     

     const downloadEan13Handler = (requestId) => {
         if (selectedRows.length > 1) {
             setErrorMessage("Выберете одну заявку")
             setSnackbarError(true);
             return;
         }
         const config = {
             headers: {
                 "Authorization": `Bearer ${localStorage.getItem('token')}`,
                 "Accept" : "*/*",
                 "Access-Control-Allow-Origin" : '*'
             },
         }

         getNameShop().then(nameShop => {
             setOpenLoading(true);
             fetch('http://45.9.40.232:8080/api/v1/barcode/ean13/' + requestId[0].id + "/" + nameShop, config)
                 .then(response => {
                     setTimeout(() => {
                         response.blob().then(blob => {
                             let url = window.URL.createObjectURL(blob);
                             let a = document.createElement('a');
                             a.href = url;
                             a.download = "etiketki-" + String(requestId[0].id) + '.pdf';
                             a.click();
                         });
                         setOpenLoading(false);
                         setSuccessMessage("Этикетки EAN13 успешно сгенерированы")
                         setSnackbar(true)
                     }, 2000)
                 })
                 .catch(err => errorConnection())
         })
     }

     const uploadCode128Handler = (e) => {
         if (selectedRows.length > 1) {
             setErrorMessage("Выберете одну заявку")
             setSnackbarError(true);
             return;
         }
         setOpenLoading(true);
         var bodyFormData = new FormData();
         bodyFormData.append('file', e.target.files[0]);
         bodyFormData.append('name', "ean13-" + String(selectedRows[0].id));
         bodyFormData.append('idRequest', selectedRows[0].id);
         const config = {
             headers: {
                 "Authorization": `Bearer ${localStorage.getItem('token')}`,
                 "Access-Control-Allow-Origin" : '*',
                 'Content-Type': 'multipart/form-data',

            }
         }
         axios({method: 'POST', url : "http://45.9.40.232:8080/api/v1/barcode/upload", data : bodyFormData, responseType: 'blob', headers : {
                 "Authorization": `Bearer ${localStorage.getItem('token')}`,
                 "Access-Control-Allow-Origin" : '*',
                 'Content-Type': 'multipart/form-data',

             } })
           .then(response => {
             //  console.log(response)
               const file = new Blob(
                   [response.data],
                   {type: 'application/pdf'});

               //const fileURL = URL.createObjectURL(file);
               let url = window.URL.createObjectURL(file);
               let a = document.createElement('a');
               a.href = url;
               a.download = "shk-korobov-" + String(selectedRows[0].id) + '.pdf';
               a.click();
               setOpenLoading(false);
               setSuccessMessage("Этикетки Code128 успешно сгенерированы")
               setSnackbar(true)
           })
             .catch(err => {
                if (err.code === "ERR_BAD_RESPONSE") {
                    setTimeout(() => {
                        setOpenLoading(false);
                        setErrorMessage("Файл некоректен или содержит поля с остатками")
                        setSnackbarError(true);
                    }, 2000)
                }
                 console.log(err)
                 //errorConnection()
             })
     }
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [value, setValue] = useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleCloseSuccess = (event, reason) => {
        setSnackbar(false);
    };

    const handleCloseError = (event, reason) => {
        setSnackbarError(false);
    };

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button variant="contained"
                color="warning"
                sx={{mb: 2}} onClick={onClick} ref={ref}>
            {value}
        </Button>
    ));

    const downloadXMLFile = () => {
        let databosy = {
            "date" : dateToYMD(date),
            "id" : value,
            "shop" : getIdShop(),
            "cises" : cises.split("\n")
        }

        axios.post("http://45.9.40.232:5001/xml", databosy).then(response => {
            const file = new Blob(
                [response.data],
                {type: 'application/xml'});
            let url = window.URL.createObjectURL(file);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'упд-WB.xml';
            a.click();
        })
    }

    const downloadZmlOzon = () => {
        let databosy = {
            "date" : dateToYMD(date),
            "id" : value,
            "shop" : getIdShop(),
            "cises" : cises.split("\n"),
            "stockName" : stockName,
            "uniqueNumber" : uniqueNumber
        }
        axios.post("http://45.9.40.232:5001/xml/ozon", databosy).then(response => {
            const file = new Blob(
                [response.data],
                {type: 'application/xml'});
            let url = window.URL.createObjectURL(file);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'упд-OZON.xml';
            a.click();
        })

    }

    return (
        <div style={{width: '100%'}}>
            <Snackbar open={snackbar} autoHideDuration={3000} onClose={handleCloseSuccess}>
                <Alert severity="success" onClose={handleCloseSuccess} >
                    <AlertTitle>Отлично</AlertTitle>
                    {successMessage} — <strong>Успех!</strong>
                </Alert>
            </Snackbar>
            <Snackbar open={snackbarError} autoHideDuration={3000} onClose={handleCloseError}>
                <Alert severity="error" onClose={handleCloseError}>
                    <AlertTitle>Ошибка!</AlertTitle>
                    {errorMessage} — <strong>Оёй!</strong>
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {edit ? <AddRequest rowsInput={inputRows} setEdit={setEdit} requestIdInput={selectedRows[0].id}/> :
                <div>
                <Button
                    disabled={selectionModel.length === 0}
                    style={{marginTop: "10px", marginLeft: "10px"}}
                    variant="contained"
                    color="warning"
                    sx={{mb: 2}}
                    onClick={(e) => editHandler(e)}
                >
                    Подробнее
                </Button>
                <Button
                disabled={selectionModel.length === 0}
                style={{marginTop: "10px", marginLeft: "10px"}}
                variant="contained"
                color="success"
                sx={{mb: 2}}
                onClick={() => downloadFileHandler(selectedRows)}
                >
                Скачать xlsx
                </Button>
                <Button
                disabled={selectionModel.length === 0}
                style={{marginTop: "10px", marginLeft: "10px"}}
                variant="contained"
                color="success"
                sx={{mb: 2}}
                onClick={() => downloadFileWBHandler(selectedRows)}
                >
                Скачать шаблон-заказа WB
                </Button>
                    <Button
                        disabled={selectionModel.length === 0}
                        style={{marginTop: "10px", marginLeft: "10px"}}
                        variant="contained"
                        color="success"
                        sx={{mb: 2}}
                        onClick={() => downloadFileOZONHandler(selectedRows)}
                    >
                        Скачать шаблон-заказа OZON
                    </Button>
                    <Button
                        disabled={selectionModel.length === 0}
                        style={{marginTop: "10px", marginLeft: "10px"}}
                        variant="contained"
                        color="success"
                        onClick={() => setOpenDialog(true)}
                        sx={{mb: 2}}
                    >
                        Скачать шк-коробы
                    </Button>

                    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                        <DialogTitle>ШК-Коробы</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Для генерации Excel файла с шк-коробами, необходимо вставить, список штих-кодов сгенерированых на Wildberries
                            </DialogContentText>
                            <DialogContentText sx={{color: 'text.primary'}}>
                                Количество: {value.split("\n").filter(x => x !== '').length}
                            </DialogContentText>
                            <TextField
                                style={{width : "100%"}}
                                id="outlined-multiline-flexible"
                                multiline
                                maxRows={4}
                                value={value}
                                onChange={handleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDialog(false)}>Отмена</Button>
                            <Button disabled={value.split("\n").filter(x => x !== '').length === 0} variant="contained" color="success" onClick={() => downloadFileShkHandler(selectedRows)}>Загрузить</Button>
                        </DialogActions>
                    </Dialog>
                <Button
                    disabled={selectionModel.length === 0}
                    style={{marginTop: "10px", marginLeft: "10px"}}
                    variant="contained"
                    color="secondary"
                    sx={{mb: 2}}
                    onClick={() => downloadEan13Handler(selectedRows)}
                >
                    Этикетки
                </Button>
                <Button
                    disabled={selectionModel.length === 0}
                    variant="contained"
                    color="secondary"
                    component="label"
                    style={{marginTop: "10px", marginLeft: "10px"}}
                    sx={{mb: 2}}
                    onChange={(e) => uploadCode128Handler(e)}
                >
                    Шк коробов
                    <input hidden accept=".xlsx" type="file" />
                </Button>
                <Button
                    style={{marginTop: "10px", marginLeft: "10px"}}
                    variant="contained"
                    color="secondary"
                    sx={{mb: 2}}
                    onClick={() => setOpenXml(true)}
                >
                    УПД WB
                </Button>
                    <Dialog open={openXml} onClose={() => setOpenXml(false)} fullWidth={true}>
                        <DialogTitle>XML-WB</DialogTitle>
                        <DialogContent style={{height: "500px"}}>
                            <br/>
                            <DialogContentText>Ид акта</DialogContentText>
                            <TextField
                                style={{width : "100%"}}
                                id="outlined-multiline-flexible"
                                multiline
                                maxRows={4}
                                value={value}
                                onChange={handleChange}
                            />
                            <br/>
                            <br/>
                            <DialogContentText>Дата акта</DialogContentText>
                            <DatePicker
                                selected={date}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => setDate(date)}
                                customInput={<ExampleCustomInput />}
                            />
                            <TextField
                                id="standard-textarea"
                                label="Кизы"
                                placeholder="Placeholder"
                                multiline
                                onChange={(e) => setCises(e.target.value)}
                                maxRows={10}
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDialog(false)}>Отмена</Button>
                            <Button disabled={value.split("\n").filter(x => x !== '').length === 0} variant="contained" color="success" onClick={() => downloadXMLFile()}>Загрузить</Button>
                        </DialogActions>
                    </Dialog>


                    <Button
                        style={{marginTop: "10px", marginLeft: "10px"}}
                        variant="contained"
                        color="secondary"
                        sx={{mb: 2}}
                        onClick={() => setXmlOzon(true)}
                    >
                        УПД OZON
                    </Button>

                    <Dialog open={xmlOzon} onClose={() => setXmlOzon(false)} fullWidth={true}>
                        <DialogTitle>УПД-OZON</DialogTitle>
                        <DialogContent style={{height: "500px"}}>
                            <br/>
                            <DialogContentText>Ид акта</DialogContentText>
                            <TextField
                                style={{width : "100%"}}
                                id="outlined-multiline-flexible"
                                multiline
                                maxRows={4}
                                value={value}
                                onChange={handleChange}
                            />
                            <DialogContentText>Уникальный номер акта</DialogContentText>
                            <TextField
                                style={{width : "100%"}}
                                id="outlined-multiline-flexible"
                                multiline
                                maxRows={1}
                                value={uniqueNumber}
                                onChange={(e) => setUniqueNumber(e.target.value)}
                            />
                            <br/>
                            <br/>
                            <DialogContentText>Дата акта</DialogContentText>
                            <DatePicker
                                selected={date}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => setDate(date)}
                                customInput={<ExampleCustomInput />}
                            />
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={stockName}
                                label="Склад"
                                onChange={(e) => setStockName(e.target.value)}
                            >
                                <MenuItem value={"Ростов"}>Ростов</MenuItem>
                                <MenuItem value={"Петровское"}>Петровское</MenuItem>
                                <MenuItem value={"Екатеринбург"}>Екатеринбург</MenuItem>
                                <MenuItem value={"Казань"}>Казань</MenuItem>
                                <MenuItem value={"Питер"}>Питер</MenuItem>
                                <MenuItem value={"Адыгейск"}>Адыгейск</MenuItem>
                            </Select>
                            <br/>
                            <TextField
                                id="standard-textarea"
                                label="Кизы"
                                placeholder="Placeholder"
                                multiline
                                onChange={(e) => setCises(e.target.value)}
                                maxRows={10}
                                variant="standard"
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setXmlOzon(false)}>Отмена</Button>
                            <Button disabled={value.split("\n").filter(x => x !== '').length === 0} variant="contained" color="success" onClick={() => downloadZmlOzon()}>Загрузить</Button>
                        </DialogActions>
                    </Dialog>

                <Link
                href="/user/dashboard/add"
                style={{marginTop: "10px", marginLeft: "10px"}}
                variant="contained"
                color="success"
                sx={{mb: 2}}
                >
                Добавить заявку
                </Link>
                    {selectionModel.length === 0 ?   <Alert severity="info">
                        <AlertTitle>Информация</AlertTitle>
                        Для активации кнопок необходимо выбрать заявку с которой вы хотите работать — <strong>Вперед!</strong>
                    </Alert> : <></>}

                <div style={{height: 650, width: '100%'}}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={25}
                    rowHeight={35}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    getRowId={(row) => row.id}
                    checkboxSelection={checkboxSelection}
                    disableMultipleSelection
                    onSelectionModelChange={(selection) => {
                    rowSelect(selection)
                    if (selection.length > 1) {
                        const selectionSet = new Set(selectionModel);
                        const result = selection.filter((s) => !selectionSet.has(s));
                        setSelectionModel(result);
                        rowSelect(result)
                    } else {
                        setSelectionModel(selection);
                        rowSelect(selection)
                        }
                    }}
                    selectionModel={selectionModel}
                />
                    </div>
                </div>
            }
        </div>
    );
}