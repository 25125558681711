import {Alert, AlertTitle, Backdrop, Button, CircularProgress, Snackbar} from "@mui/material";
import * as React from "react";
import "./requestPageCompany.css"
import {useEffect, useState} from "react";
import axios from "axios";
import {getIdShop, getIdUser} from "../../util/Service";
import {AddRequestCompany} from "../AddRequestCompany";
import fileDownload from "js-file-download";

const config = {
    headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        "Accept": "*/*",
        "Access-Control-Allow-Origin": '*',
        "Content-Type": "application/json"
    },
};

export const RequestsPageCompany = () => {

    const [requests, setRequests] = useState([]);
    const [selected, setSelected] = useState();
    const [edit, setEdit] = useState();
    const [rows, setRows] = useState();
    const [snackbarError, setSnackbarError] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [openLoading, setOpenLoading] = useState(false);
    const [supply, setSupply] = useState();

    useEffect(() => {
        axios.get('http://45.9.40.232:8080/api/v1/request/company/get/' + getIdShop(), config)
            .then(response => {
                if (response.status === 200) {
                    setRequests(response.data);
                }
            })
    }, [])

    const onClick = (id) => {
        {
            selected === id ? setSelected() : setSelected(id)
        }
    }

    const onEdit = () => {
        let rowsArray = [];

        axios.get("http://45.9.40.232:8080/api/v1/request/list/company/all/" + selected, config)
            .then((response) => {
                let inputData = response.data;
                for (let value in inputData) {
                    console.log(inputData[value])
                    let data = {"id" : null, "barcode" : null, "artcode" : null, "name" : null, "color": null, "size": null, "sole" : null, "amount": null};
                    data.id = inputData[value].idShopProduct.idProduct.id;
                    data.barcode = inputData[value].idShopProduct.idProduct.idBarcode.barcode;
                    data.artcode = inputData[value].idShopProduct.idProduct.idArtCode.artCode;
                    data.name = inputData[value].idShopProduct.idProduct.idBarcode.idModel.name;
                    data.color = inputData[value].idShopProduct.idProduct.idBarcode.idModel.idColor.colorName;
                    data.size = inputData[value].idShopProduct.idProduct.idBarcode.idModel.idSize.number;
                    data.sole = inputData[value].idShopProduct.idProduct.idBarcode.idModel.idSole.soleName;
                    data.amount = inputData[value].amount;
                    rowsArray.push(data);
                }
                axios.get("http://45.9.40.232:8080/api/v1/request/company/" + selected, config).then(r => {
                    setSupply(r.data.supply);
                    setRows(rowsArray)
                    setEdit(true);
                })
        })
    }

    const onCompanyFile = () => {
        setOpenLoading(true)
        const config = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Accept" : "*/*",
                "Access-Control-Allow-Origin" : '*'
            },
            method : "POST"
        };
        fetch("http://45.9.40.232:8080/api/v1/request/company/get/xlsx/" + selected, config).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = "filename" + String(selected) + '.xlsx';
                a.click();
            })})
            .catch(err => {
                setTimeout(() => {
                    setOpenLoading(false);
                    setErrorMessage("Ошибка загрузки")
                    setSnackbarError(true);
                }, 2000)
            })
    }

    const handleCloseSuccess = (event, reason) => {
        setSnackbar(false);
    };

    const handleCloseError = (event, reason) => {
        setSnackbarError(false);
    };

    const onReportFile = () => {
        setOpenLoading(true)
        axios({method: 'GET', url : "http://45.9.40.232:8080/api/v1/request/company/xlsx/report/" + selected, responseType: 'blob', headers : {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Access-Control-Allow-Origin" : '*',
                'Content-Type': 'multipart/form-data',

            } })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/xlsx'});

                let url = window.URL.createObjectURL(file);
                let a = document.createElement('a');
                a.href = url;
                a.download = "company-report-" + String(selected) + '.xlsx';
                a.click();
                setOpenLoading(false);
                setSuccessMessage("Файл отчета успешно загружен")
                setSnackbar(true)
            })
            .catch(err => {
                console.log(err)
                setTimeout(() => {
                    setOpenLoading(false);
                    setErrorMessage("Ошибка загрузки")
                    setSnackbarError(true);
                }, 2000)
            })
    }

    const closeRequest = () => {
        let element = requests.filter(x => x.id === selected)[0];
        setOpenLoading(true)
        axios.get("http://45.9.40.232:8080/api/v1/request/company/close/" + element.id, config)
            .then(response => {
                if (response.status === 200) {
                    setOpenLoading(false);
                    setSuccessMessage("Поставка закрыта")
                    setSnackbar(true)
                }
            })
            .catch(err => {
                setTimeout(() => {
                    setOpenLoading(false);
                    setErrorMessage("Ошибка загрузки")
                    setSnackbarError(true);
                }, 2000)
            })
    }

    return (
        <div style={{width: "100%"}}>
            {edit ? <AddRequestCompany inputRows={rows}  idRequest={selected} inputSupply={supply}/> :
                <>
                    <Button
                        onClick={onEdit}
                        disabled={selected === undefined || requests.filter(x => x.id === selected)[0].status === 'Закрыта'}
                        style={{marginTop: "10px", marginLeft: "10px"}}
                        variant="contained"
                        color="warning"
                        sx={{mb: 2}}>
                        Редактирование
                    </Button>
                    <Button
                        onClick={onCompanyFile}
                        disabled={selected === undefined}
                        style={{marginTop: "10px", marginLeft: "10px"}}
                        variant="contained"
                        sx={{mb: 2}}>
                        Для производства
                    </Button>
                    <Button
                        onClick={onReportFile}
                        disabled={selected === undefined}
                        style={{marginTop: "10px", marginLeft: "10px"}}
                        variant="contained"
                        sx={{mb: 2}}>
                        Отчет
                    </Button>
                    <Button
                        onClick={closeRequest}
                        disabled={selected === undefined || requests.filter(x => x.id === selected)[0].status === 'Закрыта'}
                        style={{marginTop: "10px", marginLeft: "10px"}}
                        variant="contained"
                        color="error"
                        sx={{mb: 2}}>
                        Закрыть поставку
                    </Button>
                    <div className="remove-model-variant">
                        <div className="card-variant">
                            <div className="request-page-company-header">
                                <h6 className="card-variant-element-text">Номер</h6>
                                <h6 className="card-variant-element-text">Дата</h6>
                                <h6 className="card-variant-element-text">Поставка</h6>
                                <h6 className="card-variant-element-text">Количество пар</h6>
                                <h6 className="card-variant-element-text">Статус</h6>
                            </div>
                            {requests.map(element => {
                                return (
                                    <div
                                        className={selected === element.id ? "request-page-company-element-selected" : "request-page-company-element"}
                                        onClick={() => onClick(element.id)}>
                                        <span>{element.id}</span>
                                        <span>{element.date}</span>
                                        <span>{element.supply}</span>
                                        <span>{element.productCount}</span>
                                        <span>{element.status}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <Snackbar open={snackbar} autoHideDuration={3000} onClose={handleCloseSuccess}>
                        <Alert severity="success" onClose={handleCloseSuccess} >
                            <AlertTitle>Отлично</AlertTitle>
                            {successMessage} — <strong>Успех!</strong>
                        </Alert>
                    </Snackbar>
                    <Snackbar open={snackbarError} autoHideDuration={3000} onClose={handleCloseError}>
                        <Alert severity="error" onClose={handleCloseError}>
                            <AlertTitle>Ошибка!</AlertTitle>
                            {errorMessage} — <strong>Оёй!</strong>
                        </Alert>
                    </Snackbar>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            }
        </div>
    )
}