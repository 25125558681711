import {TextField, Typography} from "@mui/material";
import {Button} from "@mui/joy";
import {useState} from "react";
import axios from "axios";
import {API_URL, config} from "../../../util/API";

export const InputUrl = ({counter, setCounter, setLoading, setOpenSnackbar, setMessage, setSizes, url, setUrl}) => {

    const getInfoCard = () => {
        setLoading(true)
        axios.get(API_URL + "/follow/card/" + url, config).then(response => {
            if (response.status === 200) {
                let sizes = [];
                for (let element of response.data.data.products[0].sizes) {
                    sizes.push(parseInt(element.name));
                }
                setSizes(sizes);
                setCounter(counter + 1);
            }
            else {
                setMessage("Ошибка зугрузки модели");
                setOpenSnackbar(true);
            }
        }).catch(err => {
            setMessage("Ошибка зугрузки модели");
            setOpenSnackbar(true);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <div>
            <Typography  variant="h5" gutterBottom>
                Вставьте артикул КТ
            </Typography>
            <TextField onChange={(e) => setUrl(e.target.value)} value={url} sx={{width : "100%"}} id="outlined-basic" label="Артикул" variant="outlined" />
            <Button onClick={getInfoCard} sx={{width : "100%", marginTop : "20px"}}>Далее</Button>
        </div>
    )
}