import {NavigationBar} from "../NavBar";
import {Sidebar} from "../Sidebar";
import "./userDashboard.css"
import {ProductCards} from "../ProductCards";
import React, {useState} from "react";
import {RequestsPage} from "../RequestsPage";
import { Redirect, Switch, Route, Router } from "react-router-dom";
import {AddRequest} from "../AddRequest";
import {PrivateRoute} from "../../Routes/RouteGuard"
import {AddShopProduct} from "../AddShopProduct";
import {RemoveModel} from "../RemoveModel";
import {Outlet} from "react-router-dom";

export const UserDashboard = () => {

    const [activePage, setActivePage] = useState(1);

    const pages = {1: <ProductCards key={1}/>, 0: <RequestsPage/>}

    return (
        <div className="user-dashboard">
            <Sidebar/>
            <div className="user-dashboard-content">
                <NavigationBar/>
                <div className="user-dashboard-body">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}