import {
    Alert,
    Box,
    Container,
    Modal,
    Paper,
    Skeleton,
    Snackbar,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {Button} from "@mui/joy";
import {useEffect, useState} from "react";
import {InputUrl} from "../InputUrl";
import {AmountTraking} from "../AmountTracking";
import axios from "axios";
import {API_URL, config} from "../../../util/API";
import {getIdShop} from "../../../util/Service";

export const MainPage = () => {

    const [open, setOpen] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [counter, setCounter] = useState(0);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [sizes, setSizes] = useState([]);
    const [artcode, setArtcode] = useState("");
    const [rows, setRows] = useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseSnackbar = () => setOpenSnackbar(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const skeletonStyle = {
        position: 'relative',
        width: '100%',
        height: '200px',
    }

    useEffect(() => {
        axios.get(API_URL + "/follow/" + getIdShop(), config).then(response => {
            if(response.status === 200) {
                setRows(response.data);
            }
        })
    }, [])

    return (
        <div style={{marginLeft : "20px", marginTop : "20px"}}>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Button onClick={handleOpen}>Добавить</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {loading ? <Skeleton variant="rectangular" sx={skeletonStyle} /> :
                        <>
                            {counter === 0 ? <InputUrl
                                counter={counter}
                                setCounter={setCounter}
                                setLoading={setLoading}
                                setMessage={setMessage}
                                setOpenSnackbar={setOpenSnackbar}
                                setSizes={setSizes}
                                url={artcode}
                                setUrl={setArtcode}
                                />
                                : <></>}
                            {counter === 1 ? <AmountTraking artcode={artcode} sizes={sizes}/>:<></>}
                        </>
                    }
                </Box>
            </Modal>
            <TableContainer style={{marginTop: "20px"}} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Арткод</TableCell>
                            <TableCell>Размер</TableCell>
                            <TableCell>Мин. количество</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">{row.artcode.artcode}</TableCell>
                                <TableCell>{row.size.number}</TableCell>
                                <TableCell>{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}